import React from "react";
import BankIdFrame from "./BankIdFrame";
import { Box, Container, Stack, Typography } from "@mui/material";
import CustomerLogo from "./images/CustomerLogo";
import InformationIcon from "./images/InformationIcon";
import LogoRamsay from "./images/LogoRamsay";

const AuthRoot: React.FC<{}> = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight:"100vh",
        background: "#F5F5F5",
        pl: "0px !important",
        pr: "0px !important",
      }}
    >
      <Stack spacing={0} sx={{ height: "100%" }}>
        <Container
          sx={{
            height: 56,
            // minHeight: 56,
            background: "#F6CCC3",
            pl: "0px !important",
            pr: "0px !important",
          }}
          maxWidth={false}
        >
          <Stack
            direction="row"
            sx={{
              pl: {
                xs: 3,
                md: 0,
              },
              pr: {
                xs: 3,
                md: 0,
              },
              width: {
                xs: "auto",
                sm: 768,
                md: 936,
                lg: 1128,
                xl: 1128,
              },
              m: "auto",
              height: "100%",
              alignItems: "center",
            }}
            spacing={{ xs: 1, md: 2 }}
          >
            <InformationIcon
              sx={{ height: { xs: 14, md: 16 }, width: { xs: 14, md: 16 } }}
            />
            <Box>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 14,
                  lineHeight: "16px",
                  letterSpacing: 0.5,
                }}
              >
                Vid livshotande besvär ring 112
              </Typography>
            </Box>
          </Stack>
        </Container>
        <Container
          sx={{
            height: { xs: 64, md: 72 },
            minHeight: { xs: 64, md: 72 },
            background: "#FFFFFF",
            pl: "0px !important",
            pr: "0px !important",
          }}
          maxWidth={false}
        >
          <Stack
            direction="row"
            sx={{
              pl: {
                xs: 3,
                md: 0,
              },
              pr: {
                xs: 3,
                md: 0,
              },
              width: {
                xs: "auto",
                sm: 768,
                md: 936,
                lg: 1128,
                xl: 1128,
              },
              m: "auto",
              height: "100%",
              alignItems: "center",
            }}
          >
            <CustomerLogo
              sx={{ height: { xs: 24, md: 24 }, width: { xs: 80, md: 80 } }}
            />
          </Stack>
        </Container>
        <Container
          maxWidth="xs"
        >
          <BankIdFrame
            sx={{
              borderRadius: 6,
              p: {
                xs: "32px 16px 32px 16px",
                md: 4,
              },
            }}
          />
        </Container>
        <Container
          maxWidth={false}
          sx={{
            pl: "0px !important",
            pr: "0px !important",
          }}
        >
        <Container
          sx={{
            height: { xs: 120, md: 80},
            maxHeight: { xs: 140, md: 80},
            background: "#FFFFFF",
            borderTop:"2px solid #EE9A87"
          }}
          maxWidth={false}
        >
          <Stack
            direction="row"
            sx={{
              pl: {
                xs: 3,
                md: 0,
              },
              pr: {
                xs: 3,
                md: 0,
              },
              maxWidth: {
                xs: "auto",
                sm: "80%",
              },
              m: "auto",
              height: "100%",
              alignItems: "center",
              justifyContent:"center",
              textAlign:{
                xs:"left",
                md:"center",
              }
            }}
          >
              <Typography
                sx={{
                  width:{
                    xs:"auto",
                    md:"744px",
                  },
                  fontStyle: "normal",
                  fontSize: 16,
                  lineHeight: "24px",
                  letterSpacing: 0.5,
                  "span":{
                    fontWeight:700
                  }
                }}
              >
                <span>För support:</span> kontakta helpdesk@capio.se eller ring 010-264 77 10
              </Typography>
          </Stack>
        </Container>
        <Container
          maxWidth={false}
          sx={{
            height: "100%",
            background: "#0D3050",
            pl: "0px !important",
            pr: "0px !important",
            pt: {
              xs: 8,
              md: 0,
            },
            pb: {
              xs: 8,
              md: 0,
            },
          }}
        >
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{
              pl: {
                xs: 3,
                md: 0,
              },
              pr: {
                xs: 3,
                md: 0,
              },
              width: {
                xs: "auto",
                sm: 768,
                md: 936,
                lg: 1128,
                xl: 1128,
              },
              m: "auto",
              height: "100%",
              alignItems: "center",
            }}
            spacing={{
              xs: 6,
              md: 12,
            }}
          >
            <LogoRamsay
              sx={{
                height: {
                  xs: 43,
                  md: 59,
                },
                width: {
                  xs: 140,
                  md: 192,
                },
                alignSelf: {
                  xs: "flex-start",
                  md: "auto",
                },
              }}
            />
            <Box
              sx={{
                height: {
                  xs: "100%",
                  md: "auto",
                },
                pt: {
                  md: 10,
                },
                pb: {
                  md: 10,
                },
              }}
            >
              <Typography
                sx={{
                  color: "#F9FAFB",
                  lineHeight: { xs: "24px", md: "20px" },
                  fontSize: "14px",
                }}
              >
                Capios vision är att förbättra hälsan för människor varje dag.
                Vi vill göra skillnad för våra patienter genom att se, lyssna
                och finnas här – idag och genom hela livet. Capio erbjuder ett
                brett och högkvalitativt vårdutbud genom våra sjukhus,
                specialistkliniker och vårdcentraler i Sverige, Norge och
                Danmark. Våra patienter kan även få hjälp via våra digitala
                tjänster. Capio är sedan 2018 en del av Ramsay Santé - en
                ledande vårdgivare med 36 000 medarbetare i fem länder.
                Tillsammans förbättrar vi folkhälsan – en individ i taget!
              </Typography>
            </Box>
          </Stack>
        </Container>
        </Container>
      </Stack>
    </Container>
  );
};

export default AuthRoot;
