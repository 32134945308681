import React from "react";
import { Box, BoxProps } from "@mui/material";

const LogoRamsay: React.FC<BoxProps> = (props) => {
  return (
    <Box
      component="img"
      src="/images/logoramsay.svg"
      alt="Capio del av Ramsay Santé Logo"
      {...props}
    />
  );
};

export default LogoRamsay;
