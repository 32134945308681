import React, { useEffect } from "react";

import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import { bankIdStatus, cancelLogin, checkIfBankIdLoginStarted, startBankIdLogin } from "shared/bankidApi";
import { isMobileBrowser } from "shared/utils";
import { NoEncryption, SettingsBackupRestoreTwoTone } from "@mui/icons-material";

interface BankIdFrameProps {
  sx?: SxProps<Theme>;
}

const getLoginMethodFromLoginHint = (login_hint:string) => {
  if (login_hint === "" && isMobileBrowser()) {
    return "this_device";
  } else if (login_hint === "open_this_device"){
    return "open_this_device";
  } else if (login_hint === "this_device"){
    return "this_device";
  }
  else{
    return "mobile";
  }
} 

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [] }) => {
  const [reset, setReset]=React.useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const login_challenge = searchParams.get("login_challenge") as string;
  const login_hint = searchParams.get("login_hint") ?? "";
  const [loginMethod, setLoginMethod] = React.useState(
    getLoginMethodFromLoginHint(login_hint)
  );
  const simplifiedLoginMethod = loginMethod === "open_this_device"? "this_device": loginMethod;
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [bankIdOpened, setBankIdOpened] = React.useState(false);
  const [qrCode, setQrCode] = React.useState<string | null>(null);
  const bankIdUrl = React.useRef("");
  const [userMessage, setUserMessage] = React.useState("");
  const pollingCallback = React.useRef<NodeJS.Timeout | null>(null);
  const timeoutMessage="Tiden för att läsa av QR-koden har gått ut. Försök att logga in igen. Om det inte funkar prova att uppdatera BankID-appen på din telefon.";
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLoginMethod: string | null
  ) => {
    if (newLoginMethod !== null) {
      setLoginMethod(newLoginMethod);
    }
  };

  const clearCache = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    console.log("Cache Cleared");
};

  const onBackClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("on back click")
    if (login_challenge !== null) {
      try {
        await cancelLogin(login_challenge);
      } catch {
        // no meaningful error handling here, we just go back which should reset
        // everything
      }
    }
    setBankIdOpened(false);
    console.log("Sending back user to capio")
    //history.back();//Outcommented because it doesn't work if the previous location in history is referring to eID using window.location.replace()
    window.location.replace("https://online.capio.com/");
  }; 

  const renderedLoginMethod = () => {
    console.log(login_hint)
    switch (loginMethod) {
      case "open_this_device":
        return (
          <BankIdThisDeviceLogin
            bankIdOpened={bankIdOpened}
            setBankIdOpened={setBankIdOpened}
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            startImmediately={true}
            startBankIdLogin={async (personalNumber?: string) => {
              await startBankIdLogin(
                login_challenge,
                pollingCallback,
                "this_device",
                bankIdUrl,
                setBankIdOpened,
                setLoginFailed,
                setUserMessage,
                setQrCode,
                personalNumber
              ); 
            }}
            bankIdUrl={bankIdUrl}
            userMessage={userMessage}
          />
        );
      case "this_device":
        return (
          <BankIdThisDeviceLogin
            bankIdOpened={bankIdOpened}
            setBankIdOpened={setBankIdOpened}
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            startImmediately={false}
            startBankIdLogin={async (personalNumber?: string) => {
              await startBankIdLogin(
                login_challenge,
                pollingCallback,
                loginMethod,
                bankIdUrl,
                setBankIdOpened,
                setLoginFailed,
                setUserMessage,
                setQrCode,
                personalNumber
              ); 
            }}
            bankIdUrl={bankIdUrl}
            userMessage={userMessage}
          />
        );
      case "mobile":
        return (
            <BankIdMobileLoginQr
              onBackClick={onBackClick}
              loginFailed={loginFailed}
              qrCode={qrCode}
              userMessage={timeoutMessage} 
              logInAgain={onBackClick}
            />
        );
    }
  };  
  React.useEffect(() => {
    clearCache();
    if (simplifiedLoginMethod === "mobile") {
      startBankIdLogin(
        login_challenge,
        pollingCallback,
        simplifiedLoginMethod,
        bankIdUrl,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage,
        setQrCode
      );
    } else if (simplifiedLoginMethod === "this_device") {
      checkIfBankIdLoginStarted(
        login_challenge,
        pollingCallback,
        simplifiedLoginMethod,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage
      );
    }
    return () => {
      if (pollingCallback.current) {
        clearTimeout(pollingCallback.current);
        pollingCallback.current = null;
      }
    };
  }, [login_challenge, loginMethod, reset]);

  return (
    <Container
    sx={{
      minHeight:"calc(100vh - 450px)",
    }}
    >
      <Card variant="elevation" sx={ sx={
        boxSizing:"border-box",
        width:{
          xs: "342px",
          md: "360px"
        },
        p: {
          xs: "24px 16px 16px 16px",
          md: "24px 16px 16px 16px"
        },
        borderRadius:"8px",
          m: {
            xs: "32px 0 32px 0",
            md: "40px 0 40px 0",
          },
          position:"relative",
          left:"50%",
          transform:"translateX(-50%)"
        }} elevation={0}>
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} 
        >
          <Stack
          spacing={3}>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize:{
                  xs:"32px",
                  md:"48px"
                },
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              Logga in
            </Typography>
             {/* btn group for desktop */}
            <ToggleButtonGroup
              color="primary"
              value={simplifiedLoginMethod}
              onChange={handleChange}
              exclusive
              fullWidth
              sx={{
                display:{
                  xs:"none",
                  md:"inline-flex"
                },
                height:"56px",
                background: "#EEF2F8",
                borderRadius: 6,
                "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:not(:last-of-type)":
                  {
                    color: "primary.main",
                    m: 1,
                    borderRadius: 6,
                    borderWidth: 0,
                    fontStyle: "normal",
                    textTransform:"uppercase",
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing:"1px",
                    "&:hover":{
                      backgroundColor:"secondary.main",
                      color: "#FFFFFF",
                    },
                      "&:active": {
                      backgroundColor:"#275284",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "primary.main",
                      color: "#FFFFFF",
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "secondary.main",
                        color: "#FFFFFF",
                    },
                    "&.Mui-selected:active": {
                      backgroundColor:"#275284",
                    }
                  },
              }}
            >
              <ToggleButton disableRipple={true} disableTouchRipple={true} value="this_device">denna enhet</ToggleButton>
              <ToggleButton disableRipple={true} disableTouchRipple={true} value="mobile">annan enhet</ToggleButton>
            </ToggleButtonGroup>
            {/* btn group for mobile */}
            <ToggleButtonGroup
              color="primary"
              value={simplifiedLoginMethod}
              onChange={handleChange}
              exclusive
              fullWidth
              sx={{
                display:{
                  md:"none"
                },
                height:"56px",
                background: "#EEF2F8",
                borderRadius: 6,
                "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:not(:last-of-type)":
                  {
                    color: "primary.main",
                    m: 1,
                    borderRadius: 6,
                    borderWidth: 0,
                    fontStyle: "normal",
                    textTransform:"uppercase",
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing:"1px",
                    "&:active": {
                    backgroundColor:"#275284",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "primary.main",
                      color: "#FFFFFF",
                    },
                    "&.Mui-selected:active": {
                      backgroundColor:"#275284",
                    }
                  },
              }}
            >
              <ToggleButton disableRipple={true} disableTouchRipple={true} value="this_device">denna enhet</ToggleButton>
              <ToggleButton disableRipple={true} disableTouchRipple={true} value="mobile">annan enhet</ToggleButton>
            </ToggleButtonGroup>
            {renderedLoginMethod()}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default BankIdFrame;
