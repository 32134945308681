import React from "react";
import { Box, BoxProps } from "@mui/material";

const InformationIcon: React.FC<BoxProps> = (props) => {
  return (
    <Box
      component="img"
      src="/images/icon.svg"
      alt="Informative icon"
      {...props}
    />
  );
};

export default InformationIcon;
