import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AuthRoot from "./AuthRoot";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0D3050",
    },
    secondary: {
      main: "#1C5DAC",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: ["proxima-nova"].join(","),
    fontWeightRegular: 400,
    allVariants: {
      color: "#0D3050",
    },
  },
});

const App: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthRoot />
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
